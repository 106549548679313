import { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { isPlatform } from '@ionic/core';
import {
    IonBackButton, IonButton,
    IonButtons,
    IonChip,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { auth } from '../../../firebaseConfig';
import { purchasesActions } from '../store/actions';
import keyWord from "../../../assets/icons/keywords.svg"; //!
import flashcards from "../../../assets/icons/flashcardsIcon.svg"; //!

import { Trans } from 'react-i18next';
// import { store } from '../../../common/storeHandler';
import { buy, store } from '../../../common/purchasehandler';
import { ResourceLimit } from '../store/reducers';
import { purchaseServices } from '../store/services';
import { fromResourceTypeDBToCorrectName, getPlanPrice, getStripePriceId } from '../utils/purchaseUtils';
import './Purchase.css';
import './Purchase.css';
import map from '../../../assets/icons/mapIcon.svg'
import genSumm from '../../../assets/icons/generateSummary.svg'; //!
import audio from '../../../assets/icons/speechdictionary.svg';
import colorpicker from '../../../assets/icons/eyedropper.svg';
import file from '../../../assets/icons/addPDFButton.svg'
import dictionary from '../../../assets/icons/dictionaryIos.svg'
import personalizedFont from '../../../assets/icons/personalizeFont.svg'
// import { buy, store } from '../../../common/purchasehandler';






type Props = {
    history: any,

    purchaseError: () => void,
    paymentIsLoading: (isLoading: boolean) => void,
};

type State = {
    showModal: boolean,
    isLoadingStripe: boolean,

    couponId: string | undefined,
    productOwned: boolean | undefined,

    reasyMonthlyIapProduct: IAPProduct | null,

    showCouponAppliedOnWeb: boolean,
    showHowToApplyDiscountCodeOnAndroid: boolean,
    showHowToApplyDiscountCodeOnIos: boolean,

    bearerToken: string,

    purchasableProducts: any, /* TO CHANGE ANY */
    selectedPeriod: 'monthly' | 'yearly'
};



class Purchase extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            isLoadingStripe: false,

            couponId: undefined,
            productOwned: false,

            reasyMonthlyIapProduct: null,

            showCouponAppliedOnWeb: false,
            showHowToApplyDiscountCodeOnAndroid: false,
            showHowToApplyDiscountCodeOnIos: false,

            bearerToken: '',

            purchasableProducts: null,
            selectedPeriod: 'monthly',
        }

    }

    componentDidMount(): void {
        if (isPlatform("capacitor") || isPlatform("cordova")) {
            // const reasyMonthlyIapProduct = store.get("ai.tech4all.reasy.full.monthly");
            // this.setState({ reasyMonthlyIapProduct: reasyMonthlyIapProduct });
        }
        auth.onAuthStateChanged(async (userData) => {
            if (userData) {
                userData.getIdToken().then(token => {
                    this.setState({ bearerToken: token })
                    /* ENDPOINT NOT EXPOSED YET */
                    purchaseServices.getPurhcasablePlans(token)
                        .then(response => {
                            console.log(response)
                            this.setState({ purchasableProducts: response })
                        })
                        .catch(err => {

                        })

                })
            }
        })
    }

    render() {
        return (
            <IonPage className='body'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>Acquista</IonTitle> {/* TO BE LOCALIZED */}
                        {(isPlatform("capacitor") || isPlatform("cordova")) && (
                            <IonButtons slot="end">
                                <IonButton
                                    className="purchaseIosDiscountHeaderButton"
                                    fill="clear"
                                    shape="round"
                                    onClick={() => {
                                        // store.redeem();
                                        // this.props.paymentIsLoading(true);
                                        // this.props.history.push("/processing-payment");
                                    }}
                                >
                                    <IonIcon />
                                </IonButton>
                            </IonButtons>
                        )}
                    </IonToolbar>
                </IonHeader>

                <IonGrid className="purchaseMainGrid">
                    <div className="purchaseMainDiv">
                        <div className="purchaseTitleDiv">
                            <div className="purchaseTitleInnerDiv">
                                <p className="purchaseTitleParagraph"><Trans>Raggiungi i tuoi obiettivi più velocemente</Trans></p> {/* TO LOCALIZE THIS */}
                                <p className="purchaseCTAParagraph"><Trans>Aggiorna oggi e sfrutta al massimo il tuo tempo di studio!</Trans></p> {/* TO LOCALIZE THIS */}
                            </div>
                        </div>
                        <div className='purchasePeriodToggle'>
                            <div
                                className={`colorSlider ${this.state.selectedPeriod === 'yearly' ? 'colorSliderMonthly' : ''}`}
                            ></div>
                            <IonChip
                                mode='ios'
                                className={`purchasePeriodChip monthlyChip ${this.state.selectedPeriod === 'monthly' ? 'selectedPeriod' : ''
                                    }`}
                                onClick={() => this.setState({ selectedPeriod: 'monthly' })}
                            >
                                Mensile
                            </IonChip>
                            <IonChip
                                mode='ios'
                                className={`purchasePeriodChip yearlyChip ${this.state.selectedPeriod === 'yearly' ? 'selectedPeriod' : ''
                                    }`}
                                onClick={() => this.setState({ selectedPeriod: 'yearly' })}

                            >
                                Annuale
                                <IonChip className='purchaseYearlyDiscountChip'>-17%</IonChip>
                            </IonChip>
                        </div>
                        {this.state.purchasableProducts &&
                            <div className="purchaseBenefitsDiv">
                                <div className="purchaseProductContainer">
                                    {
                                        this.state.purchasableProducts.map((product: any, i: any) => {
                                            return (
                                                <div className={`purchaseProductInfoDiv productInfoDiv-${i}`}>
                                                    <p className="purchaseProductNameParagraph">{product.name}</p>
                                                    <p className="purchaseProductPriceParagraph">€{getPlanPrice(product.id, this.state.selectedPeriod)}</p>
                                                    <div className="purchaseProductLimitDiv">
                                                        <div className="purchaseProductLimitInnerDiv">
                                                            {product.limits.map((limit: ResourceLimit) => {
                                                                return (
                                                                    /* TO LOCALIZE THIS */
                                                                    <p key={limit.id} className="purchaseProductLimitParagraph">✅ {limit.resource_type === 'tts' ? limit.quantity / 1000 / 60 : limit.quantity} {limit.resource_type !== 'tts' ? "generazioni " : 'minuti '} di <b>{fromResourceTypeDBToCorrectName(limit.resource_type)}</b>*</p>
                                                                )
                                                            })}
                                                            <p>*al mese</p> {/* TO LOCALIZE THIS */}
                                                        </div>
                                                    </div>
                                                    <div className='purchaseProductButtonDiv'>
                                                        <IonButton
                                                            onClick={() => {
                                                                /* MOBILE PROCESS */
                                                                /* TODO CHANGE FOR THE NEW BACKEND PAYMENT FLOW */
                                                                if (isPlatform("capacitor") || isPlatform("cordova")) {
                                                                    this.props.paymentIsLoading(true);
                                                                    this.props.history.push("/processing-payment");

                                                                    buy.call(this, product.id, this.state.selectedPeriod)
                                                                    /* store
                                                                        .order("ai.tech4all.reasy.full.monthly")
                                                                        .then((e: any) => {
                                                                            console.log("[Purchase] purchase response:", e);
                                                                            this.props.paymentIsLoading(true);
                                                                            this.props.history.push("/processing-payment");
                                                                        })
                                                                        .error((e: any) => {
                                                                            console.error("[Purchase] purchase error:", e);
                                                                            this.props.purchaseError();
                                                                            this.props.history.push("/processing-payment");
                                                                        }); */
                                                                }
                                                                /* WEB PROCESS */
                                                                else {
                                                                    /* CREATE STRIPE CUSTOMER ID */
                                                                    this.setState({ isLoadingStripe: true })
                                                                    let priceId = getStripePriceId(product.id, this.state.selectedPeriod)
                                                                    purchaseServices.createOrRetrieveStripeCustomerId(this.state.bearerToken)
                                                                        .then(response => {
                                                                            /* ONCE WE HAVE THE CUSTOMER ID -> CREATE A CHECKOUT SESSION */
                                                                            let customerId = response
                                                                            purchaseServices.createStripeCheckoutSession(customerId, priceId, this.state.bearerToken)
                                                                                .then(response => {
                                                                                    this.setState({ isLoadingStripe: false })
                                                                                    window.open(response.checkout_url, '_self')
                                                                                })
                                                                                .catch(err => {
                                                                                    this.setState({ isLoadingStripe: false })
                                                                                    console.error("[Purchase] error creating checkout session:", err);
                                                                                })
                                                                        })
                                                                        .catch(err => {
                                                                            this.setState({ isLoadingStripe: false })
                                                                            console.error("[Purchase] error getting customer id:", err);
                                                                        })
                                                                    /* AND REDIRECT TO STRIPE CHECKOUT "checkout_url" */

                                                                }
                                                            }}
                                                            className={`purchaseButton purchase-${i}`}
                                                            size="large"
                                                        >
                                                            <Trans>
                                                                Abbonati
                                                            </Trans>
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        }

                        <div className="purchaseButtonsAndTermsDiv">

                            {(isPlatform("ios") && (isPlatform("capacitor") || isPlatform("cordova"))) && (
                                <div
                                    className="purchaseRestorePurchasesDiv"
                                    onClick={() => {
                                        store.restorePurchases()
                                    }}
                                >
                                    Ripristina acquisti {/* TO BE LOCALIZED */}
                                </div>
                            )}


                            <div className="purchaseTermsDiv">
                                <p>
                                    L'abbonamento viene rinnovato automaticamente alla fine
                                    del periodo di fatturazione.
                                    <br />
                                    Per maggiori dettagli, leggere la nostra{" "}
                                    <a
                                        href="https://tech4all-ai.notion.site/Payment-policy-2017d47b36ea4e2ab72d0f2a1c0a55cc"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Payment Policy
                                    </a>{" "}
                                    e la{" "}
                                    <a
                                        href="https://app.reasyedu.com/legal/privacy-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </p>
                                {(isPlatform("capacitor") || isPlatform("cordova")) &&
                                    isPlatform("ios") && (
                                        <p>
                                            You can modify or cancel our subscription by going
                                            to the user's iTunes Account Settings after
                                            purchase.
                                            <br />
                                            Any unused portion of a free trial period, if
                                            offered, will be forfeited when the user purchases a
                                            subscription to that publication.
                                            <br />
                                            Payment will be charged to iTunes Account at
                                            confirmation of purchase.
                                        </p>
                                    )}

                                {(isPlatform("capacitor") || isPlatform("cordova")) &&
                                    isPlatform("android") && (
                                        <p>
                                            You can modify or cancel your subscription by going
                                            to the user's Play Service Account Settings after
                                            purchase.
                                            <br />
                                            Any unused portion of a free trial period, if
                                            offered, will be forfeited when the user purchases a
                                            subscription to that publication.
                                            <br />
                                            Payment will be charged to Play Service Account at
                                            confirmation of purchase.
                                        </p>
                                    )}
                            </div>
                        </div>
                    </div>
                </IonGrid>

                <IonLoading
                    message={
                        "Sarai reindirizzato/a alla pagina di pagamento"
                    } /* TO LOCALIZE THIS */
                    isOpen={this.state.isLoadingStripe}
                />
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        purchaseError: () => {
            dispatch(purchasesActions.error());
        },

        paymentIsLoading: (isLoading: boolean) => {
            dispatch(purchasesActions.loading(isLoading));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
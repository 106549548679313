import React, { useState, useRef, useEffect } from "react";
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonPopover, IonModal } from "@ionic/react";
import "./SummaryViewer.css";
import ColorPicker from "../components/colorPicker";
import boldIcon from "../../../assets/icons/oi--bold.svg";
import italicIcon from "../../../assets/icons/fa-solid--italic.svg";
import { colorFillSharp } from "ionicons/icons";
import { summaryServices } from "../store/services";
import { auth } from "../../../firebaseConfig";

interface Block {
	id: string;
	content: string;
	type: string;
	style: {
		bold: boolean;
		italic: boolean;
	};
}

interface Paragraph {
	id: string;
	blocks: Block[];
}

interface Page {
	id: string;
	paragraphs: Paragraph[];
}

interface Summary {
	pages: Page[];
}
// Define props interface
interface SummaryViewerProps {
	history: {
		location: {
			state: {
				resourceId: string;
			};
		};
	};
}

const SummaryViewer: React.FC<SummaryViewerProps> = (props) => {
	const [isSpan, setIsSpan] = useState<{ style: string; active: boolean }>({ style: "", active: false });
	const [txtColor, setTxtColor] = useState<{ color: string }>({ color: "var(--txt-color)" });
	const [showColorPicker, setShowColorPicker] = useState<boolean>(true);

	const storedData = localStorage.getItem("editorData");
	const parsedData: Summary = JSON.parse(storedData!);

	const [data, setData] = useState<Summary>(parsedData);

	const editorRef = useRef<HTMLDivElement>(null);
	
	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.innerHTML = renderContent();
		}
		getSummaryText()
	}, []);
	const getSummaryText = ()=>{
		auth.currentUser?.getIdToken().then(async token=>{
			let summaryContent =await summaryServices.getSummary(props.history.location.state.resourceId,token)
			console.log(summaryContent)
			const parsedData: Summary = JSON.parse(summaryContent.content);
console.log(parsedData)
			setData(parsedData)
		})
	}
	
	const renderContent = () => {
		return data.pages
			.map((page, pageIndex) => {
				const pageContent = page.paragraphs
					.map((paragraph) => {
						return paragraph.blocks
							.map((block) => {
								const style = [];
								if (block.style.bold) style.push("bold");
								if (block.style.italic) style.push("italic");

								let tag;
								switch (block.type) {
									case "header":
										tag = "h1";
										break;
									case "sub_header":
										tag = "h2";
										break;
									case "bp":
										tag = "li";
										break;
									default:
										tag = "p";
								}

								return `<${tag} class="${style.join(" ")}" data-block-id="${block.id}">${block.content}</${tag}>`;
							})
							.join("");
					})
					.join("");

				return `<div class="page" contentEditable="true" id="page-${pageIndex + 1}">${pageContent}</div>`;
			})
			.join("");
	};

	const handleContentChange = () => {
		console.log("SAVING...");
		if (editorRef.current) {
			const newPages: Page[] = Array.from(editorRef.current.children)
				.filter((child) => child.classList.contains("page"))
				.map((pageElement, pageIndex) => {
					const htmlPageElement = pageElement as HTMLElement;
					const blocks: Block[] = Array.from(htmlPageElement.children).map((blockElement) => {
						const htmlBlockElement = blockElement as HTMLElement;
						return {
							id: htmlBlockElement.dataset.blockId || `block_${pageIndex}_${htmlBlockElement.dataset.blockIndex}`,
							content: htmlBlockElement.innerHTML || "",
							type:
								htmlBlockElement.tagName.toLowerCase() === "h1"
									? "header"
									: htmlBlockElement.tagName.toLowerCase() === "h2"
									? "sub_header"
									: htmlBlockElement.tagName.toLowerCase() === "li"
									? "bp"
									: "text",
							style: {
								bold: htmlBlockElement.classList.contains("bold"),
								italic: htmlBlockElement.classList.contains("italic"),
							},
						};
					});

					// Group blocks into paragraphs
					const paragraphs: Paragraph[] = [
						{
							id: `paragraph_${pageIndex}_0`,
							blocks: blocks,
						},
					];

					return {
						id: htmlPageElement.id || `page_${pageIndex}`,
						paragraphs: paragraphs,
					};
				});

			setData((prevData) => {
				const updatedData: Summary = {
					...prevData,
					pages: newPages,
				};

				// Save updated data to localStorage
				localStorage.setItem("editorData", JSON.stringify(updatedData));

				return updatedData;
			});
		}
	};

	const applyStyle = (style: "bold" | "italic" | "color") => {
		setIsSpan((prevState) => ({
			style: style,
			active: !prevState.active,
		}));
	};
	useEffect(() => {
		const selection = window.getSelection();

		if (selection && selection.rangeCount > 0) {
			const range = selection.getRangeAt(0);
			const selectedElement = range.startContainer.parentElement as HTMLElement;
			const currentFontWeight = window.getComputedStyle(selectedElement).fontWeight;
			const currentFontStyle = window.getComputedStyle(selectedElement).fontStyle;
			const currentColorStyle = window.getComputedStyle(selectedElement).color;
			if (selectedElement.tagName.toLowerCase() === "span") {
				if (isSpan.style === "bold")
					if (currentFontWeight !== "400") {
						selectedElement.style.fontWeight = "normal";
					} else {
						selectedElement.style.fontWeight = "bold";
					}
				else if (isSpan.style === "italic")
					if (currentFontStyle !== "normal") {
						selectedElement.style.fontStyle = "normal";
					} else {
						selectedElement.style.fontStyle = "italic";
					}
				else if (isSpan.style === "color") selectedElement.style.color = txtColor.color;

				const newFontWeight = window.getComputedStyle(selectedElement).fontWeight;
				const newFontStyle = window.getComputedStyle(selectedElement).fontStyle;
				const newColorStyle = window.getComputedStyle(selectedElement).color;
				if (newFontWeight === "400" && newFontStyle === "normal" && newColorStyle === "var(--txt-color)") {
					const parentElement = selectedElement.parentElement;
					if (parentElement) {
						// Replace the span with its text content
						const textContent = selectedElement.textContent || "";
						const textNode = document.createTextNode(textContent);
						parentElement.replaceChild(textNode, selectedElement);
					}
				}
			} else {
				if (selection && !selection.isCollapsed) {
					const range = selection.getRangeAt(0);
					const span = document.createElement("span");
					span.classList.add("styled");
					span.style[isSpan.style === "bold" ? "fontWeight" : isSpan.style === "italic" ? "fontStyle" : "color"] =
						isSpan.style === "bold" ? "bold" : isSpan.style === "italic" ? "italic" : txtColor.color;
					// Extract the contents of the range
					const fragment = range.extractContents();

					// Wrap the extracted content with the span
					span.appendChild(fragment);

					// Insert the wrapped content back into the document
					range.insertNode(span);

					// Collapse the selection to the end
					selection.collapseToEnd();
				}
			}
			handleContentChange();
		}
	}, [isSpan]);

	const handleColorConfirm = (color: string) => {
		console.log("Selected color:", color);
		setTxtColor({ color: color });
		applyStyle("color");
		// You can perform any action with the confirmed color here
		// For example, update state, send to a server, etc.
	};
	return (
		<IonPage className="summaryViewerContainer">
			<IonHeader className="summaryViewerHeader">
				<IonToolbar className="summaryViewerToolbar">
					<IonButtons className="summaryViewerToolbarBtns" slot="start">
						<IonButton onClick={() => applyStyle("bold")}>
							<IonIcon icon={boldIcon} />
						</IonButton>
						<IonButton onClick={() => applyStyle("italic")}>
							<IonIcon icon={italicIcon} />
						</IonButton>
						<IonButton onClick={() => setShowColorPicker((prevState) => !prevState)}>
							<IonIcon icon={colorFillSharp} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="summaryViewerContent">
				<div className="" ref={editorRef} onInput={handleContentChange} />
			</IonContent>
			<div hidden={showColorPicker}>
				<ColorPicker onColorConfirm={handleColorConfirm} />
			</div>
		</IonPage>
	);
};

export default SummaryViewer;

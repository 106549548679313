import {
	IonButton,
	IonGrid, IonHeader, IonIcon, IonImg,
	IonLoading,
	IonPage, IonSelect, IonSelectOption, IonToast,
	isPlatform
} from '@ionic/react';
import { GoogleAuthProvider, OAuthCredential, OAuthProvider, getAuth, getIdTokenResult, getRedirectResult, linkWithCredential, linkWithRedirect, signInWithCredential, signInWithCustomToken, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { chevronDownOutline, globeOutline, logoApple, logoGoogle, logoMicrosoft } from 'ionicons/icons';
import React from 'react';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import reasyLogoBook from '../../../assets/icons/reasy_logo_book.svg';
import reasyScritta from '../../../assets/icons/reasy_scritta.svg';
import check from '../../../assets/illustrations/check.json';
import { appleAuthProvider, auth, microsoftAuthProvider } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import './AuthenticationGetStarted.css';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import {
	SignInWithApple,
	SignInWithAppleResponse,
	SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import Cookies from 'js-cookie'
import { userServices } from '../../user/store/services';
type Props = {
	history: any,
	isAuthenticated: boolean,
}

type State = {
	policyChecked: boolean,
	email: string | null | undefined,
	isValidEmail: boolean,
	showConfirmationLabel: boolean,
	language: string,
	languageChanged: boolean,
	pendingCred: OAuthCredential | null,

	showErrorMessageToast: boolean,
	errorMessage: string | null,
	isLoading: boolean,

	fromInvitation: boolean,
	fromReferral: boolean,
	referrerName: string,
}

class AuthenticationGetStarted extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			policyChecked: false,
			email: '',
			isValidEmail: false,
			showConfirmationLabel: false,
			language: "",
			languageChanged: false,
			pendingCred: null,

			showErrorMessageToast: false,
			errorMessage: null,
			isLoading: false,

			fromInvitation: false,
			fromReferral: false,
			referrerName: '',
		}

	}
	componentDidMount(): void {
		// console.log(i18n.language, this.state.languageChanged)
		if (!this.state.languageChanged) {
			switch (i18n.language) {
				case "en-GB":
					this.setState({ language: "en" });
					break;
				case "en-US":
					this.setState({ language: "en" });
					break;
				case "it-IT":
					this.setState({ language: "it" });
					break;
				case "es-ES":
					this.setState({ language: "es" });
					break;
				default:
					this.setState({ language: i18n.language })
			}
		}
		const urlParams = new URLSearchParams(window.location.search);
		const textValue = urlParams.get('topic');
		const referral_code = urlParams.get('referral_code');
		const invitationToken = Cookies.get('org_invitation_token');
		const invitationResponse = Cookies.get('invitation_response');

		if (invitationToken && invitationResponse) {
			/* COMING FROM /invitation PAGE -> redirect to /invitation after login */
			this.setState({ fromInvitation: true })
		}

		if (textValue) {
			// Decode the URL-encoded value
			const decodedValue = decodeURIComponent(textValue);
			console.log("TEXT VALUE:", decodedValue);

			const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minutes from now
			Cookies.set('topicCookie', decodedValue, { path: '/', expires: expirationTime });
		}
		if (referral_code) {
			this.setState(({ fromReferral: true }))
			const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minutes from now
			Cookies.set('referral_code', referral_code, { path: '/', expires: expirationTime });
			userServices.getReferrerInfo(referral_code)
				.then(info => {
					this.setState({ referrerName: info.first_name })
				})
				.catch(err => {

				})
		}



	}

	fromErrorToLocalizedErrorMessage(error: string, language: string) {
		switch (error) {
			case "auth/account-exists-with-different-credential":

				switch (language) {
					case "it":
						return "È già stato effettuato l'accesso con un altro metodo di autenticazione. Scegliere un altro provider di autenticazione.";
					case "es":
						return "Ya ha iniciado sesión utilizando otro método de autenticación. Elija otro método de autenticación.";
					case "ca":
						return "Ja heu iniciat la sessió utilitzant un altre mètode d'autenticació. Trieu un altre proveïdor d'autenticació.";
					case "fr":
						return "Vous vous êtes déjà connecté en utilisant une autre méthode d'authentification. Choisissez un autre fournisseur d'authentification.";
					case "de":
						return "Sie haben sich bereits mit einer anderen Authentifizierungsmethode angemeldet. Wählen Sie einen anderen Authentifizierungsanbieter.";
					case "pt":
						return "Já iniciou sessão utilizando outro método de autenticação. Escolha outro fornecedor de autenticação.";

					default:
						return "You have already signed in by using another authentication method. Choose another authentication provider."
				}

			default:
				return "";
		}
	}


	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: check,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice"
			}
		};


		return (

			<IonPage className='authGetStartedPage'>
				<div className='authGetStartedContainer'>
					<IonHeader hidden/* ={!isMobile} */ className='ion-no-border authSupportHeader authGetStartedHeader'>

					</IonHeader>
					<IonGrid className="authGetStartedMainGrid">
						{
							isPlatform("ios") ?
								<>
									<div className="authGetStartedTextDiv">
										<h2>
											<Trans>Get started</Trans>
										</h2>
									</div>
								</> :
								this.state.fromReferral && this.state.referrerName ?
									<>
										<div className="authGetStartedTextDiv">
											<h2>
												<Trans>{this.state.referrerName} ti ha invitato su</Trans>
											</h2>
										</div>
									</>
									:
									<>
										<div className="authGetStartedTextDiv">
											<h2>
												<Trans>Get started for Free</Trans>
											</h2>
										</div>
									</>
						}

						<div className="authGetStartedIllustrationDiv">
							<IonImg src={reasyLogoBook} />
							<IonIcon className='authLoginLogoTxt' src={reasyScritta} />
						</div>

						<div className="authGetStartedButtonsDiv">
							<IonButton
								color={"dark"}
								className="authGetStartedContinueWithButton"
								onClick={async () => {
									
									if (isMobile && isPlatform("capacitor")) {
										const googleUser = await GoogleAuth.signIn() as any;
										this.setState({ isLoading: true })
										const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
										signInWithCredential(auth, credential)
											.then(result => {
												this.setState({ isLoading: false })
												if (result) {
													if (this.state.fromInvitation)
														this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
													else
														this.props.history.replace("/");
												}
												else {
													this.setState({ isLoading: false })
													console.error("SIGN IN - NO USER");
												}
											})
											.catch(err => {
												console.error("SIGN IN ERROR: ", err);

											})
										return
										/* signInWithRedirect(auth, googleAuthProvider).then(()=>{
											const googleUser = await GoogleAuth.signIn();
											const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken)
											signInWithCredential(auth, credential)
												.then((result) => {
													console.log("DID I SIGN IN? ", result);
													this.props.history.replace("/");
												})
												.catch(err => {
													console.log("ERROR DURING SIGNIN IN: ", err);
	
												})
											});
											return */

										/* 	signInWithRedirect(auth, googleAuthProvider)
												.then((result) => {
													console.log("redirect result: ", result)
													return getRedirectResult(auth);
												})
												.then(result => {
													console.log("[AuthGetStarted] google successful redirect login:", result);
													this.props.history.replace("/");
												})
												.catch((err) => {
													console.error("ERROR SIGNIN IN WITH REDIRECT: ", err);
	
												}) */
									}
									else

									GoogleAuth.signIn().then(googleUser=>{
										if (googleUser) {
											const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
											signInWithCredential(auth, credential)
											.then(result => {
												this.setState({ isLoading: true })
												if (this.state.pendingCred) {
													linkWithCredential(result.user, this.state.pendingCred)
														.then(res => {
															this.setState({ isLoading: false })
															console.log("[AuthGetStarted] google with linked credentials:", res);
															if (result.user) {
																if (this.state.fromInvitation)
																	this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
																else
																	this.props.history.replace("/");
															}
														})
														.catch(err => {
															this.setState({ isLoading: false })
															console.error("[AuthGetStarded] google - error linking credentials:", err);
															if (result.user) {
																if (this.state.fromInvitation)
																	this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
																else
																	this.props.history.replace("/");
															}
														})
												}
												else {
													if (result.user) {
														if (this.state.fromInvitation)
															this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
														else
															this.props.history.replace("/");
													}
												}
											})}})
									// 		})
									// 		.catch(err => {
									// 			console.error("[CredentialsSignup] google error authenticating:", err);
									// 			const credential = GoogleAuthProvider.credentialFromError(err);
									// 			console.error("[AuthGetStarted] google error authenticating. credentials:", credential);
									// 			if (err.code === "auth/account-exists-with-different-credential") {
									// 				this.setState({
									// 					pendingCred: credential,
									// 					showErrorMessageToast: true,
									// 					errorMessage: this.fromErrorToLocalizedErrorMessage(err.code, this.state.language),
									// 				});
									// 			}
									// 		})
								}}
							>
								<IonIcon
									className="authGetStartedContinueWithButtonIcon"
									icon={logoGoogle}
								/>
								<Trans>Continue with</Trans> Google

							</IonButton>

							<IonButton
								hidden={isMobile}
								color={"dark"}
								className="authGetStartedContinueWithButton"
								onClick={async () => {
									signInWithPopup(auth, microsoftAuthProvider)
										.then(result => {
											this.setState({ isLoading: true })
											const credential = OAuthProvider.credentialFromResult(result);
											const accessToken = credential!.accessToken;
											const idToken = credential!.idToken;
											if (credential) {
												linkWithCredential(result.user, credential)
													.then(res => {
														this.setState({ isLoading: false })
														console.log("[AuthGetStarted] microsoft with linked credentials:", res);
														if (result.user) {
															if (this.state.fromInvitation)
																this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
															else
																this.props.history.replace("/");
														}
													})
													.catch(err => {
														this.setState({ isLoading: false })
														console.error("[AuthGetStarded] microsoft - error linking credentials:", err);
														if (result.user) {
															if (this.state.fromInvitation)
																this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
															else
																this.props.history.replace("/");
														}
													})
											}
											else {
												if (result.user) {
													if (this.state.fromInvitation)
														this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
													else
														this.props.history.replace("/");
												}
											}
										})
										.catch(err => {
											console.error("[AuthGetStarted] microsoft error signin-up:", err);
											const credential = OAuthProvider.credentialFromError(err);
											if (err.code === "auth/account-exists-with-different-credential") {
												console.log("[AuthGetStarted] saving microsoft pending user");
												this.setState({
													pendingCred: credential,
													showErrorMessageToast: true,
													errorMessage: this.fromErrorToLocalizedErrorMessage(err.code, this.state.language)
												});
											}
										})
								}}
							>
								<IonIcon
									icon={logoMicrosoft}
									className="authGetStartedContinueWithButtonIcon"
								/>
								<Trans>Continue with</Trans> Microsoft
							</IonButton>

							<IonButton
								color={"dark"}
								hidden={isAndroid && isPlatform('capacitor')}
								className="authGetStartedContinueWithButton"
								onClick={() => {
									if (isPlatform('capacitor') && isIOS) {
										let options: SignInWithAppleOptions = {
											clientId: 'ai.tech4all.reasy',
											redirectURI: 'localhost:8100/files/my-files',
											scopes: 'email name',
											// state: '12345',
											// nonce: 'nonce',
										};
										SignInWithApple.authorize(options)
											.then((result: SignInWithAppleResponse) => {
												this.setState({ isLoading: true })
												// console.log("SIGNED IN with apple: ", result);
												// Handle user information
												// Validate token with server and create new session
												let credentials = appleAuthProvider.credential({ idToken: result.response.identityToken })
												signInWithCredential(auth, credentials)
													.then(response => {
														this.setState({ isLoading: false })
														// console.log("SIGNED IN WITH CREDENTIAL: ", response);
														if (response.user) {
															if (this.state.fromInvitation)
																this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
															else
																this.props.history.replace("/");
														}
													})
													.catch(err => {
														this.setState({ isLoading: false })
														console.error("[AuthGetStarted] apple error signin-up:", err);
													})
											})
											.catch((error) => {
												console.log("ERROR SIGNING IN: ", error);
											});

									}

									else
										signInWithPopup(auth, appleAuthProvider)
											.then(result => {
												this.setState({ isLoading: true })
												console.log("[AuthGetStarted] apple result:", result);
												const credentials = OAuthProvider.credentialFromResult(result);
												console.log("[AuthGetStarted] apple credentials:", credentials);

												if (this.state.pendingCred) {
													linkWithCredential(result.user, this.state.pendingCred)
														.then(res => {
															this.setState({ isLoading: false })
															console.log("[AuthGetStarted] apple with linked credentials:", res);
															if (result.user) {
																if (this.state.fromInvitation)
																	this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
																else
																	this.props.history.replace("/");
															}
														})
														.catch(err => {
															this.setState({ isLoading: false })
															console.error("[AuthGetStarded] apple - error linking credentials:", err);
															if (result.user) {
																if (this.state.fromInvitation)
																	this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
																else
																	this.props.history.replace("/");
															}
														})
												}
												else {
													if (result.user) {
														if (this.state.fromInvitation)
															this.props.history.push({ pathname: "/invitation", state: { respondedToInvitation: true } });
														else
															this.props.history.replace("/");
													}
												}
											})
											.catch(err => {
												console.error("[AuthGetStarted] apple error signin-up:", err);
												const credential = OAuthProvider.credentialFromError(err);
												if (err.code === "auth/account-exists-with-different-credential") {
													console.log("[AuthGetStarted] saving apple pending user");
													this.setState({
														pendingCred: credential,
														showErrorMessageToast: true,
														errorMessage: this.fromErrorToLocalizedErrorMessage(err.code, this.state.language)
													});
												}
											})
								}}
							>
								<IonIcon
									icon={logoApple}
									className="authGetStartedContinueWithButtonIcon"
								/>
								<Trans>Continue with</Trans> Apple
							</IonButton>

						</div>

						<div className="getStartedLegalTermsDiv">
							<p>
								<Trans>By registering you confirm that you have read and understood our</Trans>&nbsp;
								<a
									href='https://app.reasyedu.com/legal/privacy-policy'
									target='_blank'
									rel="noopener noreferrer"
								>
									<Trans>privacy policy</Trans> {/* TO LOCALIZE THIS */}
								</a>, <Trans>the</Trans>&nbsp;
								<a
									href='https://app.reasyedu.com/legal/cookie-policy'
									target='_blank'
									rel="noopener noreferrer"
								>
									<Trans>cookie policy</Trans> {/* TO LOCALIZE THIS */}
								</a>
								<Trans> e i </Trans><a
									href='https://app.reasyedu.com/legal/terms-conditions'
									target='_blank'
									rel="noopener noreferrer"
								>
									<Trans>terms and conditions</Trans>
								</a>.
							</p>
						</div>


						<div /* hidden={isMobile}  */ className='languageContainer-Auth cardBg'>
							<div className='settingsDocumentViewingItemDiv' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
								<IonIcon className="lnIcon" slot='start' icon={globeOutline} />
								<IonSelect
									style={{ width: 'fit-content', paddingLeft: '25px', }}
									className='appLanguageSelect'
									id="setLanguage"
									value={this.state.language}
									toggleIcon={chevronDownOutline}
									//value={this.state.language == "" ? i18n.language : this.state.language}
									interface={isMobile ? 'action-sheet' : 'popover'}
									onIonChange={e => {
										if (e.detail.value != i18n.language) {
											i18n.changeLanguage(e.detail.value);
											this.setState({ languageChanged: true, language: e.detail.value }, () => {
											})
										}
									}}
								>
									<IonSelectOption value="it">Italiano</IonSelectOption>
									<IonSelectOption value="en">English</IonSelectOption>
									<IonSelectOption value="fr">Français</IonSelectOption>
									<IonSelectOption value="es">Español</IonSelectOption>
									<IonSelectOption value="de">Deutsch</IonSelectOption>
									<IonSelectOption value="ca">Català</IonSelectOption>
								</IonSelect>
							</div>
						</div>
					</IonGrid>

					<IonToast
						onDidDismiss={() => this.setState({ showErrorMessageToast: false, errorMessage: "" })}
						isOpen={this.state.showErrorMessageToast}
						message={this.state.errorMessage ? this.state.errorMessage : ""}
						duration={3000}
					/>
					<IonLoading
						isOpen={this.state.isLoading}
						message={
							i18n.language === 'it' ? 'Caricamento...' :
								i18n.language === 'en' ? 'Loading...' :
									i18n.language === 'fr' ? 'Chargement...' :
										i18n.language === 'es' ? 'Cargando...' :
											i18n.language === 'de' ? 'Laden...' :
												i18n.language === 'ca' ? 'Carregant...' :
													i18n.language === 'pt' ? 'Carregando...' :
														i18n.language === 'cz' ? 'Carregando...' :
															i18n.language === 'nl' ? 'Bezig met laden...' : ''

						}
					/>
					{this.props.isAuthenticated && <Redirect to="/" />}
				</div >
			</IonPage >
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.access ? true : false,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationGetStarted);
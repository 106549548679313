import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonImg, IonInput, IonLoading, IonPage, IonPopover, IonSpinner, IonToolbar, isPlatform } from "@ionic/react";
import { doc, getDoc } from "firebase/firestore";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import React from "react";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import { sendEvent } from "../../../common/amplitudeUtils";
import { auth, firestore, remoteConfig } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { Organization } from "../../user/store/reducers";
import { Argument, Old_Resource, Resource, Session } from "../store/reducers";
import map3D from "../../../assets/icons/map3D.png";
import summary3D from "../../../assets/icons/summary3D.png";
import keywords3D from "../../../assets/icons/keywords3D.png";
import audio3D from "../../../assets/icons/audio3D.png";
import flashCards from "../../../assets/icons/flashCards.png";
import repeatIcon from "../../../assets/icons/repeatIcon.png";
import "./SessionDetails.css";
import { store } from "../../../common/store";
import { argumentServices, deleteSessionElement, getLocalStorageSessionState, setLocalStorageSessionState } from "../store/services";
import { checkStatusBar, clearSessionState, getStudySession, isImageValid, uploadFile } from "../utils/utils";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import removeResourceIcon from "../../../assets/icons/removeResourceIcon.svg";
import { isIOS, isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import { settingsServices, UserSettings } from "../../settings/store/services";

type Props = {
	history: any;
	orgData: Organization | null;
};

type State = {
	userUuid: string | null;
	userArguments: Argument[];
	// userArguments: any[],
	remoteConfig: boolean;
	bearerToken: string;
	newArgument: string;
	isProcessingResources: boolean;
	argument: Session | null;
	missingResources: string[];
	summarydocID: string;
	missingSummary: boolean;
	missingAudio: boolean;
	deleteElements: boolean;
	resourceToRemove: string | null,
	isLoadingMap: boolean;
	isLoadingSummary: boolean;
	isLoadingKeywords: boolean;
	isLoadingFlashcards: boolean;
	isLoadingAudiobook: boolean;
	isFetchingResources: boolean;
	audioLink: string;
	argumentID: string;
	showConfirmationPopover: boolean;
	deleting: boolean,

	showArgumentInputPopover: boolean;
	missingResourceArgumentString: string;
	selectedMissingResourceName: string;
	ttsSettings: UserSettings;
	resourceId:string;
	primaryLanguage:string
};

class SessionDetails extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			userUuid: null,
			userArguments: [],
			remoteConfig: false,
			bearerToken: "",
			newArgument: "",
			isProcessingResources: false,
			argument: null,
			missingResources: [],
			summarydocID: "",
			missingSummary: false,
			missingAudio: false,
			audioLink: "",
			argumentID: "",
			deleteElements: false,
			resourceToRemove: "",
			showConfirmationPopover: false,

			isLoadingMap: false,
			isLoadingSummary: false,
			isLoadingKeywords: false,
			isLoadingFlashcards: false,
			isLoadingAudiobook: false,
			isFetchingResources: false,
			deleting: false,

			showArgumentInputPopover: false,
			missingResourceArgumentString: "",
			selectedMissingResourceName: "",
			ttsSettings: {
				speech_rate: 1,
				speech_gender: "",
			},
			resourceId:"",
			primaryLanguage:"",
		};
	}

	componentDidMount() {
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue });
				}
			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged((userData) => {
			if (userData) {
				userData.getIdToken().then((response) => {
					this.setState({ bearerToken: response, userUuid: userData.uid }, () => {
						if (this.props.history.location.state.argument) {
							let argument = this.props.history.location.state.argument;
							if (argument) {

								this.setState({ argument: argument ,isFetchingResources: true}, () => {
									argumentServices.getUserSessions(userData.uid, this.state.bearerToken, argument.id)
										.then(response => {
											// console.log("new backend resources: ", response[0].resources); //get the first and ONLY element you find
											this.setState(prevState => ({
												argument: {
													...prevState.argument!, resources: response[0].resources
												}
											}),()=>{											this.setState({ isFetchingResources: false })
											console.log(this.state.argument)})
										})
										.catch(err => {
											this.setState({ isFetchingResources: false });
											/* TO LOCALIZE THIS */
											alert(i18n.t("Errore nel caricamento delle risorse, se il problema persiste contatta l'assistenza"));
											this.props.history.push('/sessions')
										})
									// console.log(this.state.argument);
								})
							}


						}
						// window.addEventListener('beforeunload', clearSessionState);


					});
					settingsServices.getPrimaryLanguage(userData.uid,response).then(response=>{
						console.log(response)
						this.setState({primaryLanguage:response})
					})
					settingsServices.getUserSettings(userData.uid,response).then((settings) => {
						this.setState({ ttsSettings: settings });
					});
				});



			} else {
				this.setState({
					userUuid: null,
				});
			}
		});
	}
	componentWillUnmount(): void {
		window.removeEventListener('beforeunload', clearSessionState);

	}

	reloadArguments(){
		let argument = this.props.history.location.state.argument;
		this.setState({ argument: argument }, () => {
			if(this.state.userUuid)
				argumentServices.getUserSessions(this.state.userUuid, this.state.bearerToken, argument.id)
					.then(response => {
						this.setState({ isFetchingResources: false })
						// console.log("new backend resources: ", response[0].resources); //get the first and ONLY element you find
						this.setState(prevState => ({
							argument: {
								...prevState.argument!, resources: response[0].resources
							}
						}),()=>{console.log(this.state.argument)})
					})
					.catch(err => {
						this.setState({ isFetchingResources: false });
						/* TO LOCALIZE THIS */
						alert(i18n.t("Errore nel caricamento delle risorse, se il problema persiste contatta l'assistenza"));
						this.props.history.push('/sessions')
					})
			// console.log(this.state.argument);
		})
	}

	handleSessionState(sessionType: string) {
		let sessionKey = sessionType === "creation" ? "creationSessionState" : "sessionState";
		let storedState = localStorage.getItem(sessionKey);
		let localStorageState = storedState ? JSON.parse(storedState) : [false, null];

		if (localStorageState) {
			let sessionTypes = getLocalStorageSessionState(sessionType, this.state.argument?.id);
			console.log(`${sessionType === "creation" ? "CREATION " : ""}SESSION TYPES:`, sessionTypes);
			let openedConnections = new Set(); // Track opened connections to avoid duplicates

			if (sessionTypes.length > 0) {
				sessionTypes.forEach(session => {
					let sessionResourceType = sessionType === "creation" ? session[2] : session;
					let additionalData = sessionType === "creation" ? session.slice(3) : [];

					switch (sessionResourceType) {
						case "map":
							this.setState({ isLoadingMap: true });
							break;
						case "summaries":
							this.setState({ isLoadingSummary: true });
							break;
						case "keywords_collections":
							this.setState({ isLoadingKeywords: true });
							break;
						case "flashcards_collections":
							this.setState({ isLoadingFlashcards: true });
							break;
						default:
							this.setState({ isLoadingAudiobook: true });
					}
				});
			}
		}
	}

	async mapClicked(resourceId: string) {
		sendEvent({
			"user_id": this.state.userUuid,
			"event_type": "Map visited",
			"event_properties": {
				"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
				"document_name": this.state.argument?.title ? this.state.argument.title : this.state.argument?.prompt ? this.state.argument?.prompt : "undefined",
			},
			"language": i18n.language,
			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
			"app_version": appVersion,
			"time": Date.now(),
		});
		this.props.history.push({
			pathname: '/maps',
			state: {
				session: this.state.argument,
				mapId: resourceId,
			}
		})
	}
	async summaryClicked(resourceId: string) {
		sendEvent({
			"user_id": this.state.userUuid,
			"event_type": "Summary visited",
			"event_properties": {
				"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
				"document_name": this.state.argument?.title ? this.state.argument.title : this.state.argument?.prompt ? this.state.argument?.prompt : "undefined",
			},
			"language": i18n.language,
			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
			"app_version": appVersion,
			"time": Date.now(),
		});
		this.props.history.push({
			pathname: `/summary/${resourceId}`,
			state: { session: this.state.argument, resourceId:resourceId,keywords:false },
		})
	}
	async keywordsClicked(resourceId: string) {
		sendEvent({
			"user_id": this.state.userUuid,
			"event_type": "Keywords visited",
			"event_properties": {
				"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
				"document_name": this.state.argument?.title ? this.state.argument.title : this.state.argument?.prompt ? this.state.argument?.prompt : "undefined",
			},
			"language": i18n.language,
			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
			"app_version": appVersion,
			"time": Date.now(),
		});
		this.props.history.push({
			pathname: `/summary/${resourceId}`,
			state: { session: this.state.argument, resourceId:resourceId, keywords:true },
		})
	}
	async audiobookClicked(resourceId: string) {
		if (resourceId) {
			sendEvent({
				"user_id": this.state.userUuid,
				"event_type": "Audiobook visited",
				"event_properties": {
					"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
					"document_name": this.state.argument?.title ? this.state.argument.title : this.state.argument?.prompt ? this.state.argument?.prompt : "undefined",
				},
				"language": i18n.language,
				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				"app_version": appVersion,
				"time": Date.now(),
			});
			this.props.history.push({
				pathname: `/audiobook`,
				state: {
					argument: this.state.argument,
					audiobookId: resourceId,
				},
			});
		}
	}
	async flashCardsClicked(resourceId: string) {
		if (resourceId) {
			sendEvent({
				"user_id": this.state.userUuid,
				"event_type": "Flashcards visited",
				"event_properties": {
					"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
					"document_name": this.state.argument?.title,
				},
				"language": i18n.language,
				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				"app_version": appVersion,
				"time": Date.now(),
			});
			this.props.history.push({
				pathname: `/flashcards`,
				state: {
					argument: this.state.argument,
					flashcardUuid: resourceId,
				},
			});
		}
	}
	startResourceLoading(resourceName: string) {
		console.log(resourceName);
		document.getElementById(`delete-${resourceName}`)!.style.display = "none"
		document.getElementById(`delete-spinner-${resourceName}`)!.style.display = "block"
		resourceName === "maps"
			? this.setState({ isLoadingMap: true })
			: resourceName === "summaries"
				? this.setState({ isLoadingSummary: true })
				: resourceName === "keywords_collections"
					? this.setState({ isLoadingKeywords: true })
					: resourceName === "flashcards_collections"
						? this.setState({ isLoadingFlashcards: true })
						: this.setState({ isLoadingAudiobook: true });
	}

	generateMissingResource(resourceName: string) {
		/* TODO REBUILD ALL THIS LOGIC */
		if (auth.currentUser && auth.currentUser.uid && this.state.argument) {
			auth.currentUser.getIdToken().then(async (response) => {
				resourceName === "maps"
					? this.setState({ isLoadingMap: true })
					: resourceName === "summaries"
						? this.setState({ isLoadingSummary: true })
						: resourceName === "keywords_collections"
							? this.setState({ isLoadingKeywords: true })
							: resourceName === "flashcards_collections"
								? this.setState({ isLoadingFlashcards: true })
								: this.setState({ isLoadingAudiobook: true });
				// if (/* was not from file */)
				/* argumentServices.getSessionArguments
					.call(this, "", response, auth.currentUser!.uid, this.state.argument!.title, this.state.argument?.title, resourceName, this.state.argument!)
					.then((resp) => {
						console.log("generated new resource: ", resp);
						// let missingRes = this.state.missingResources
						// missingRes.splice(missingRes.indexOf(resourceName), 1)
						window.location.reload();
					})
					.catch((err) => {
						//handle err
					}); */
			});
		}
	}

	paletteColors = ["#C7DEFF", "#FFCCDC", "#BDBDFF", "#FCD9CA", "#C7F6D1"];
	render() {
		return (
			<IonPage className="sessionDetailsPage body">

				{this.state.argument && (
					<div className="sessionDetailsMainDiv">
						{(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}  ></IonToolbar>}

						<IonButton
							className="sessionsDetailsGoBackBtn"
							onClick={() => {
								this.props.history.push("/sessions");
							}}
						>
							<Trans>
								Torna indietro
							</Trans>
						</IonButton>
						<div className="sessionsHomeOnboardingTitle">
							<p className="sessionDetailTitle">"{this.state.argument?.title}"</p>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<IonButton
									className="sessionDetailRemoveButton"
									style={{ fontSize: ".4em", cursor: "pointer", borderRadius: "20px" }}
									onClick={() => {
										if (!this.state.deleteElements) {
											this.state.argument!.resources && Object.keys(this.state.argument!.resources).map((resource, i) => {
												let resourceData = Object.values(this.state.argument!.resources!)[i][0]
												if(resourceData){
													console.log(resourceData)

													let element = document.getElementById(`card-${resource}`);
													if(element){
														document.getElementById(`delete-${resource}`)!.style.display = "block"
														element.classList.add("wobbleAnimation");
														(element as HTMLElement).style.padding = "0";
														(element as HTMLElement).style.paddingBottom = "15px";
														this.setState({ deleteElements: true });
													}
												}
											})

										} else {
											this.state.argument!.resources && Object.keys(this.state.argument!.resources).map((resource, i) => {
													let element = document.getElementById(`card-${resource}`);
													if(element){
														document.getElementById(`delete-${resource}`)!.style.display = "none"
														element.classList.remove("wobbleAnimation");
														(element as HTMLElement).style.padding = "unset";
														(element as HTMLElement).style.paddingBottom = "15px";
														this.setState({ deleteElements: false });
													}

											})

											// let elements = document.getElementsByClassName("sessionDetailsResourceCard");
											// for (let i = 0; i < elements.length; i++) {
											// 	elements[i].classList.remove("wobbleAnimation");
											// 	(elements[i] as HTMLElement).style.padding = "unset";
											// 	(elements[i] as HTMLElement).style.paddingBottom = "15px";
											// }
										}
									}}
								>
									{this.state.deleteElements ?
										i18n.t("Annulla") :
										i18n.t("Rimuovi risorsa")
									}
								</IonButton>
							</div>
						</div>

						<div className={"sessionDetailsResourcesGrid fullGrid"}>


							{this.state.argument.resources && Object.keys(this.state.argument.resources).map((resource, i) => {
								let resourceData = Object.values(this.state.argument!.resources!)[i][0] //selecting the first and only resource present -> only one resource per session at the moment
								return (
									<IonCard
										className={"sessionDetailsResourceCard fullGridCard"}
										style={{ background: this.paletteColors[i] }}
										disabled={(this.state.isLoadingMap && resource == "maps") ||
											(this.state.isLoadingSummary && resource === "summaries") ||
											(this.state.isLoadingKeywords && resource === "keywords_collections") ||
											(this.state.isLoadingFlashcards && resource === "flashcards_collections") ||
											(this.state.isLoadingAudiobook && resource === "audiobooks")}
										key={i}
										id={`card-${resource}`}
										onClick={() => {
											if (resourceData && resourceData.id) {
												if (resource === "maps") {
													this.mapClicked(resourceData.id,);
												} else if (resource === "summaries") {
													this.summaryClicked(resourceData.id);
												} else if (resource === "keywords_collections") {
													this.keywordsClicked(resourceData.id);
												} else if (resource === "audiobooks") {
													this.audiobookClicked(resourceData.id);
												} else if (resource === "flashcards_collections") {
													this.flashCardsClicked(resourceData.id);
												}
											}
											else {
													let prompt=""
													let title=""
													let content=""
													if(this.state.argument?.prompt===null && this.state.argument.title){
														prompt=this.state.argument.title
														title=this.state.argument.title
														content=this.state.argument.title
													}
													else if(this.state.argument && this.state.argument && this.state.argument.prompt && this.state.argument.title){
														prompt=this.state.argument.prompt
														title=this.state.argument.title
														content=this.state.argument.prompt
													}
												if (resource === "maps") {
													this.setState({isLoadingMap:true})
													argumentServices.createConceptualMap(this.state.bearerToken,this.state.primaryLanguage,this.state.argument?.id,prompt).then(()=>{
														this.setState({isLoadingMap:false})
														this.reloadArguments()
													})
												} else if (resource === "summaries") {
													this.setState({isLoadingSummary:true})
													argumentServices.createSummary(this.state.bearerToken,this.state.primaryLanguage,this.state.argument?.id,content,prompt,title).then(()=>{
														this.setState({isLoadingSummary:false})
														this.reloadArguments()
													})
												} else if (resource === "keywords_collections") {
													this.setState({isLoadingKeywords:true})
													argumentServices.createKeywordsCollection(this.state.bearerToken,this.state.primaryLanguage,this.state.argument?.id,prompt,title).then(()=>{
														this.setState({isLoadingKeywords:false})
														this.reloadArguments()
													})
												} else if (resource === "audiobooks") {
													this.setState({isLoadingAudiobook:true})
													argumentServices.createAudiobook(this.state.bearerToken,this.state.argument?.id,prompt,this.state.primaryLanguage).then(()=>{
														this.setState({isLoadingAudiobook:false})
														this.reloadArguments()
													})
												} else if (resource === "flashcards_collections") {
													this.setState({isLoadingFlashcards:true})
													argumentServices.createFlashcardsCollection(this.state.bearerToken,this.state.primaryLanguage,this.state.argument?.id,prompt,title).then(()=>{
														this.setState({isLoadingFlashcards:false})
														this.reloadArguments()
													})
												}
											}
										}}
										onMouseOver={(e) => {
											if (!resourceData) {
												document.getElementById(`resourceImg-${i}`)!.style.opacity = "1";
												(document.getElementById(`resourceImg-${i}`) as HTMLIonImgElement)!.src = repeatIcon;
											}
										}}
										onMouseOut={(e) => {
											if (!resourceData) {
												document.getElementById(`resourceImg-${i}`)!.style.opacity = ".2";
												(document.getElementById(`resourceImg-${i}`) as HTMLIonImgElement)!.src =
													resource == "maps"
														? map3D
														: resource == "summaries"
															? summary3D
															: resource == "keywords_collections"
																? keywords3D
																: resource == "audiobooks"
																	? audio3D
																	: resource == "flashcards_collections"
																		? flashCards
																		: "";
											}
										}}
									>
										<IonSpinner className="delete-icon" id={`delete-spinner-${resource}`}/>

										<IonIcon
											id={`delete-${resource}`}
											icon={removeResourceIcon}
											className="delete-icon"
											onClick={(event) => {
												event.stopPropagation();
												this.startResourceLoading(resource);
												this.setState({ resourceToRemove: resource, showConfirmationPopover: true, deleting: true, resourceId:resourceData.id });
											}}
										/>
										<IonCardContent className="sessionDetailsResourceCardContent">
											<IonImg
												id={`resourceImg-${i}`}
												className={resourceData ? "sessionDetailsResourceIcon" : "sessionDetailsResourceIcon missingResourceImage"}
												style={!resourceData ? { opacity: 0.2 } : {}}
												src={
													resource == "maps"
														? map3D
														: resource == "summaries"
															? summary3D
															: resource == "keywords_collections"
																? keywords3D
																: resource == "audiobooks"
																	? audio3D
																	: resource == "flashcards_collections"
																		? flashCards
																		: ""
												}
											/>
											{!resourceData &&
												<IonSpinner
													hidden={
														(!this.state.isLoadingMap &&
															!this.state.isLoadingSummary &&
															!this.state.isLoadingKeywords &&
															!this.state.isLoadingAudiobook &&
															!this.state.isLoadingFlashcards) ||
														(resource === "flashcards_collections" && !this.state.isLoadingFlashcards) ||
														(resource === "maps" && !this.state.isLoadingMap) ||
														(resource === "audiobooks" && !this.state.isLoadingAudiobook) ||
														(resource === "summaries" && !this.state.isLoadingSummary) ||
														(resource === "keywords_collections" && !this.state.isLoadingKeywords)
													}
													name="crescent"
												/>
											}
										</IonCardContent>
										<IonCardTitle className="sessionDetailsResourceType">
											{i18n.t(
												resource == "maps"
													? "Mappa"
													: resource == "summaries"
														? "Riassunto"
														: resource == "keywords_collections"
															? "Parole chiave"
															: resource == "audiobooks"
																? "Audiolibro"
																: resource == "flashcards_collections"
																	? "Flashcards"
																	: ""
											)}
										</IonCardTitle>
									</IonCard>
								);
							})}
							{/* TO REMOVE THIS AFTER IMPLEMENTING THE RESOURCE REGENERATION */}

						</div>
						<IonPopover
							isOpen={this.state.showArgumentInputPopover}
							onDidDismiss={() => this.setState({ showArgumentInputPopover: false, selectedMissingResourceName: "" })}
							className="argumentInputPopover"
						>
							<div className="argumentInputPopoverDiv">
								<p className="argumentInputPopoverTitle">Inserisci l' argomento {/* to localize this */}</p>
								<IonInput
									className="argumentInputPopoverInput"
									onInput={(e) => {
										if (e.currentTarget.value)
											this.setState({ missingResourceArgumentString: e.currentTarget.value.toString(), newArgument: e.currentTarget.value.toString() });
									}}
									debounce={200}
									placeholder="Inserisci l'argomento..."
								/>
								<IonButton
									className="argumentInputPopoverButton"
									disabled={this.state.missingResourceArgumentString.trim().length < 3}
									onClick={() => {
										// Add argument to the session
										//TODO REBUILD LOGIC FOR REGENERATION
										/*
										let resourceName = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? "summary" : this.state.selectedMissingResourceName;
										console.log("REGENERATE:", resourceName);
										if (this.state.argument && this.state.selectedMissingResourceName) getStudySession.call(this, resourceName, this.state.argument);
										this.setState({ showArgumentInputPopover: false, selectedMissingResourceName: "" });
										this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary")
											? this.setState({ isLoadingAudiobook: true, isLoadingSummary: true })
											: this.startResourceLoading(this.state.selectedMissingResourceName);
										let resourcesNames = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? ["audio", "summary"] : resourceName
										setLocalStorageSessionState(true, this.state.argument!.uuid, resourcesNames, "creation", this.state.argument?.title)
*/
									}}
								>
									Conferma {/* TO LOCALIZE THIS */}
								</IonButton>
								<p
									onClick={() => {
										/* HANDLE FILE UPLOAD */
										document.getElementById("argumentFile")?.click();
									}}
									className="sessionsHomeUploadParagraph"
								>
									oppure carica 📤 un documento
								</p>
							</div>
						</IonPopover>
						<IonPopover
							isOpen={this.state.showConfirmationPopover}
							onDidDismiss={() => {
								this.state.resourceToRemove === 'maps' ? this.setState({ isLoadingMap: false }) :
									this.state.resourceToRemove === 'summaries' ? this.setState({ isLoadingSummary: false }) :
										this.state.resourceToRemove === 'keywords_collections' ? this.setState({ isLoadingKeywords: false }) :
											this.state.resourceToRemove === 'flashcards_collections' ? this.setState({ isLoadingFlashcards: false }) :
												this.setState({ isLoadingAudiobook: false })
								this.setState({
									resourceToRemove: "",
									showConfirmationPopover: false,
								});
							}}
							className="argumentInputPopover"
						>
							<div className="deleteConfimrationPopoverDiv">
								<p className="deleteConfimrationPopoverTitle">Vuoi davvero rimuovere l'elemento? {/* to localize this */}</p>
								<div className="deleteConfimrationPopoverBtns">
									<IonButton
										className="deleteConfimrationPopoverButton"
										onClick={() => {
											this.setState({ showConfirmationPopover: false });
											deleteSessionElement.call(this);
											console.log(this.state.argument?.id)
											setLocalStorageSessionState(true, this.state.argument!.id, this.state.resourceToRemove?.toLowerCase(), "")
										}}
									>
										Conferma {/* TO LOCALIZE THIS */}
									</IonButton>
									<IonButton
										className="argumentDismissBtn"
										onClick={() => {
											document.getElementById(`delete-${this.state.resourceToRemove}`)!.style.display = "block"
											document.getElementById(`delete-spinner-${this.state.resourceToRemove}`)!.style.display = "none"

											this.setState({ showConfirmationPopover: false })
										}}
									>
										Annulla {/* TO LOCALIZE THIS */}
									</IonButton>
								</div>
							</div>
						</IonPopover>
					</div>
				)}
				{/* {this.state.isFetchingResources &&
					/* TO LOCALIZE THIS
					<IonLoading isOpen={this.state.isFetchingResources} message={i18n.t('Sto caricando le risorse...')} />
				} */}
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetails);

import { Product } from "../../../common/Paywall/store/paywallUtils"
import { userConstants } from "./constants"

export interface User {
    id: string,
    is_superuser: boolean,
    email: string,
    org_name: string,
    is_staff: boolean,
    is_active: boolean,
    date_joined: string,
    last_login: string
}

export interface ReferredUser {
    id: string,
    referrer_id: string,
    referred_id: string,
    referral_date: string,
    first_name: string,
    subscribed: boolean,
}
export interface ReferrerInfo {
    first_name: string,
    referral_code: string,
}
export interface UserData {
    id: string,
    email: string,
    first_name: string | null,
    last_name: string | null,
    created_at: number,
    marketing_consent: number,
    theme: string | null
    primary_language: string | null,
    stripe_customer_id: string | null,
    referral_code: string | null
}

export interface OrganizationMembership {
    id: string,
    user_id: string,
    org_id: string,
    role: "member" | "admin",
    joined_at: string,
}
export interface Organization_New { /* to RENAME this after MIGRATION */
    id: string,
    name: string,
    created_at: string,
    logo_s3_key: string | null,
    membership: OrganizationMembership

}

export interface Organization {/* TO REMOVE THIS AFTER MIGRATION */
    uuid: string,
    createdAt: number,
    createdBy: string,
    name: string,
    type: "school" | "publisher" | "company",
    products: Product[],
}
export interface Notification {
    id: string,
    title: string,
    body: string,
    date: number,
    url: string | null,
    expiryDate: number | null,
    type: "offer" | "announcement" | "reminder" | "other",
}

export interface UserStore {
    userData: User | null,
    organization_name: string | null
    organization: Organization | null | 'Private'
    licenseBannerRedeemed: boolean
    // are_notifications_active: boolean,
}

export interface Device {
    uuid: string,
    creationDate: number,
    deviceId?: string,
    isActive: boolean,
    name?: string,
    registrationToken: string,
    type: number,
}

export interface PlanInfo {
    id: string,
    name: string
}

export interface UserLicense {
    id: string,
    user_id: string,
    org_id: string | null,
    org_license_id: string | null,
    subscription_id: string,
    plan_id: string,
    created_at: string,
    start_at: string,
    end_at: string,
    plan_info: PlanInfo,
}


const initialState: UserStore = {
    userData: null,
    organization_name: null,
    organization: null,
    licenseBannerRedeemed: false,
    // are_notifications_active: boolean,
}


const User = (state: any = initialState, action: any) => {
    switch (action.type) {
        case userConstants.SET_ORGANIZATION:
            return {
                ...state,
                organization: action.org_data || null, // Provide a fallback in case org_data is undefined
            };
        case userConstants.SET_BANNER_LICENSE_REDEEMED:
            console.log("SET IT TO: ", action.isRedeemed);

            return {
                ...state,
                licenseBannerRedeemed: action.isRedeemed, // Provide a fallback in case org_data is undefined
            };
        default:
            return state;
    }
};

export default User;
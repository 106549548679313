import React, { CSSProperties, useEffect, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './colorPicker.css'
import moveArrows from '../../../assets/icons/arrowsMove.svg'
interface ColorSliderProps {
  color: string;
  value: number;
  onChange: (value: number) => void;
}

const ColorSlider: React.FC<ColorSliderProps> = ({ color, value, onChange }) => {
  const sliderColor = color.toLowerCase();

  // Extend CSSProperties to accept custom properties like --slider-color
  const sliderStyle: CSSProperties & { [key: string]: string } = {
    '--slider-color': sliderColor
  };

  return (
    <div className="flex items-center mb-2">
      <label className="w-10 mr-2">{color}</label>
      <input
        type="range"
        min="0"
        max="255"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        className="w-full"
        style={sliderStyle} // apply the custom CSS variable
      />
      <span className="w-10 ml-2 text-right">{value}</span>
    </div>
  );
};

interface ColorPickerProps {
  onColorConfirm: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onColorConfirm }) => {
  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [position, setPosition] = useState({ top: 50, left: 50 });
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 }); // Initial drag position


  const hexColor = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;

  const handleConfirm = () => {
    onColorConfirm(hexColor);
  };

// Start dragging on mousedown
const handleMouseDown = (e: React.MouseEvent) => {
  setIsDragging(true);
  setDragStart({
    x: e.clientX - position.left,
    y: e.clientY - position.top,
  });
};

// Handle dragging with mousemove
const handleMouseMove = (e: MouseEvent) => {
  if (isDragging) {
    setPosition({
      top: e.clientY - dragStart.y,
      left: e.clientX - dragStart.x,
    });
  }
};

// Stop dragging on mouseup
const handleMouseUp = () => {
  setIsDragging(false);
};

useEffect(() => {
  if (isDragging) {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  } else {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  }

  return () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };
}, [isDragging]);



  return (
    <div className="popup"

    style={{ top: position.top, left: position.left }}>
    <IonIcon
        icon={moveArrows}
        className="move-btn"
        onMouseDown={handleMouseDown}

      >
      </IonIcon>   
      <ColorSlider color="Red" value={red} onChange={setRed} />
      <ColorSlider color="Green" value={green} onChange={setGreen} />
      <ColorSlider color="Blue" value={blue} onChange={setBlue} />
      <div className="mt-4 flex items-center">
        <div
          className="w-16 h-16 rounded-md mr-4"
          style={{ backgroundColor: hexColor }}
        ></div>
        <span className="font-mono">{hexColor}</span>
      </div>
      <IonButton 
        onClick={handleConfirm}
        className="mt-4 w-full"
      >
        Confirm Color
      </IonButton>
    </div>
  );
};

export default ColorPicker;
import { isPlatform } from "@ionic/core";
import { actionCreator } from "../modules/purchase/store/actions";
import { store as reduxStore } from "./store";
import { auth } from "../firebaseConfig";
import "cordova-plugin-purchase";
import axios from "axios";
import { getAndroidPriceId, getApplePriceId } from "../modules/purchase/utils/purchaseUtils";
import { backendApi } from "./apiService";

export let store:CdvPurchase.Store
let PLATFORM:CdvPurchase.Platform
// const products: any = getPlatformDetails();
let receipt = {};
function storeHandler() {
	const { ProductType, Platform, LogLevel } = window.CdvPurchase;
	PLATFORM = isPlatform('android') ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE

	store=CdvPurchase.store
	store.ready(() => {
		// alert("[storeAndroidHandler] Store is ready.");
		console.log("[purchaseHandler] Store is ready.", store.localReceipts);

	});

	 store.register(isPlatform('android') ? [
		{
			id: "ai.tech4all.reasy.base",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.GOOGLE_PLAY,
		},
		{
			id: "ai.tech4all.reasy.pro",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.GOOGLE_PLAY,
		}
	] : [
		{
			id: "ai.tech4all.reasy.base.monthly",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.APPLE_APPSTORE,
		},
		{
			id: "ai.tech4all.reasy.base.yearly",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.APPLE_APPSTORE,
		},
		{
			id: "ai.tech4all.reasy.pro.monthly",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.APPLE_APPSTORE,
		},
		{
			id: "ai.tech4all.reasy.pro.yearly",
			type: ProductType.PAID_SUBSCRIPTION,
			platform: Platform.APPLE_APPSTORE,
		}
	])

	store.when().productUpdated(onProductUpdated);
	store.when().approved((product) => {
		console.log("[purchaseHandler] transaction Approved", JSON.stringify(product));
		product.finish();
	});

	store.when().finished((product) => {
		console.log("[purchaseHandler] transaction finished. Type:", product);
		reduxStore.dispatch(actionCreator.processing(true));
		if(isPlatform("android")) receipt=product
		if (receipt) sendReceipt(receipt);
	});
	store.when().receiptsVerified((receiptsVerified) => {
		console.log("[purchaseHandler] User owns reasy", receiptsVerified);
	});
	store.when().receiptUpdated((receipts) => {
		console.log("[purchaseHandler] Receipt updated event triggered.");
		//send receip to the backend
		receipt = receipts;
	});
	store.initialize([PLATFORM])

	store.error(err => {
		// alert('store error: ' + err.message)
	})

}

function sendReceipt(receipt: any) {
	try {
		const currentPlatform = isPlatform('android') ? 'android' : isPlatform('ios') ? 'apple' : null;

		auth.currentUser?.getIdToken().then((token) => {
			if (!token || !currentPlatform) {
				console.error("[purchaseHandler] No token or platform received");
				return reduxStore.dispatch(actionCreator.error());
			}

			const url = currentPlatform === 'apple' 
				?"/apple/receipts" 
				: "/android/purchases";

			const data = currentPlatform === 'apple' 
				? { receipt: receipt } 
				: { transaction: receipt };

			backendApi.post(url, data, {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				console.log("[purchaseHandler] response:",response.status, response);
				reduxStore.dispatch(actionCreator.success())
			})
			.catch((error) => {
				if (error.response) {
					console.error("[purchaseHandler] response error:", error.response.data);
				} else if (error.request) {
					console.error("[purchaseHandler] request error:", error.request);
				} else {
					console.error("[purchaseHandler] error:", error.message);
				}
				reduxStore.dispatch(actionCreator.error());
			});
		}).catch((error) => {
			console.error("[purchaseHandler] Token error:", error);
			reduxStore.dispatch(actionCreator.error());
		});
	} catch (error) {
		console.error("Error sending receipt:", error);
		reduxStore.dispatch(actionCreator.processing(false));
		reduxStore.dispatch(actionCreator.error());
	}
}


function onProductUpdated(product: any) {
	console.log("[storeAndroidHandler] Product updated:", JSON.stringify(product));
	// const productDetails = store.get(product.id, PLATFORM);
	// console.log(productDetails);
}

export function buy(this: any, planId: string, period: 'monthly' | 'yearly') {
	// alert('buy function: ' + planId + " " + period)
	const productId = PLATFORM === 'ios-appstore' ? getApplePriceId(planId, period) : getAndroidPriceId(planId, period)
	// alert(productId + " " + PLATFORM)
	const test= store.products
	// alert(test)
	const product = store.get(productId, PLATFORM);
	const offer = product?.getOffer();
	// alert(offer?.id)

	if (offer) {
		offer.order().then((error) => {
			if (error) {
				// alert(error.code)
				if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
					this.props.paymentIsLoading(false);
					this.props.history.push("/purchase");
				} else {
					this.props.paymentIsLoading(false);
					this.props.history.push("/purchase");
				}
			}
		});
	}
}

// function getPlatformDetails() {
// 	if (isPlatform("ios") && (isPlatform("capacitor") || isPlatform("cordova"))) {
// 		return [
// 			{
// 				id: "ai.tech4all.reasy.base.monthly",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.APPLE_APPSTORE,
// 			},
// 			{
// 				id: "ai.tech4all.reasy.base.yearly",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.APPLE_APPSTORE,
// 			},
// 			{
// 				id: "ai.tech4all.reasy.pro.monthly",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.APPLE_APPSTORE,
// 			},
// 			{
// 				id: "ai.tech4all.reasy.pro.yearly",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.APPLE_APPSTORE,
// 			}
// 		];
// 	} else if (isPlatform("android") && (isPlatform("capacitor") || isPlatform("cordova"))) {
// 		return [
// 			{
// 				id: "ai.tech4all.reasy.base",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.GOOGLE_PLAY,
// 			},
// 			{
// 				id: "ai.tech4all.reasy.pro",
// 				type: ProductType.PAID_SUBSCRIPTION,
// 				platform: Platform.GOOGLE_PLAY,
// 			}
// 		]
// 	}
// }

export default storeHandler;
